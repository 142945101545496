import {
    Grid,
    Skeleton,
    Container,
    Paper,
    Flex,
    Rating,
    Text,
    Title,
    createStyles,
    Box,
    keyframes,
} from "@mantine/core";
import star from "../assets/filledstar.svg";

const child = <Skeleton height={140} radius="md" animate={false} />;

export const reviews = [
    {
        "@type": "Review",
        author: "Prasoon Sunder",
        datePublished: "2022-10-05",
        reviewBody: `I have never seen a mining engineering test series with such a well put together interface before. The questions were challenging enough to make it difficult, but not too hard that I couldn't finish them all in time. I loved the questions and think this is the best mining test series on the market today!`,
        name: "Loved performance tracking",
        reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1",
        },
    },
    {
        "@type": "Review",
        author: "Sumantha Bhuyan",
        datePublished: "2022-10-10",
        reviewBody: `best platform to practice minining study`,
        name: "Loved performance tracking",
        reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1",
        },
    },
    {
        "@type": "Review",
        author: "Ratan Chohan",
        datePublished: "2022-10-16",
        reviewBody: `I have been using Miningtest.tech for a few months now, and I am really impressed with the high quality of their material. The questions in the test series are GATE level, which is very helpful in preparing for my upcoming test. Their interface is also very intuitive and performance metrics are good. Highly recommended!`,
        name: "Loved performance tracking",
        reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1",
        },
    },
    {
        "@type": "Review",
        author: "Nupoor Venkatesh",
        datePublished: "2022-10-10",
        reviewBody: `It took me a long time to decide which test series was the best for me. I tried almost all the test series available but this one worked best. It offered great material per question and has shown its effectiveness from my past exams. I would say it's an easy choice if the GATE exam is the only exam you need to prepare for!`,
        name: "Loved performance tracking",
        reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1",
        },
    },
    {
        "@type": "Review",
        author: "Sudheer Chandra",
        datePublished: "2022-10-10",
        reviewBody: `Best test series for gate mining engineering`,
        name: "Loved performance tracking",
        reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1",
        },
    },
    {
        "@type": "Review",
        author: "Sandeep Singh",
        datePublished: "2022-10-10",
        reviewBody: `loved the analytics after each exams`,
        name: "Loved performance tracking",
        reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1",
        },
    },
    {
        "@type": "Review",
        author: "Surya Kala",
        datePublished: "2022-10-10",
        reviewBody: `it's the best available`,
        name: "Loved performance tracking",
        reviewRating: {
            "@type": "Rating",
            bestRating: "5",
            ratingValue: "5",
            worstRating: "1",
        },
    },
];

const pulse = keyframes({
    from: {
        backgroundPosition: "0px 0px",
    },
    to: {
        backgroundPosition: "500px 0px",
    },
});

const scaler = keyframes({
    "0%": {
        transform: "scale(1)",
    },
    "20%": { transform: "scale(1.05)" },
    "80%": { transform: "scale(1.05)" },
    to: {
        transform: "scale(1)",
    },
});

const useStyles = createStyles((theme) => ({
    bg: {
        backgroundImage:
            theme.colorScheme == "light"
                ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='544' height='65.3' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23F6F6F6' stroke-width='7.5' stroke-opacity='0.73'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E")`
                : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='544' height='65.3' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='7.5' stroke-opacity='0.72'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E")`,

        animation: `${pulse} 10s ease-in-out alternate infinite`,
    },

    review: {
        "&:hover": {
            transform: "scale(1.05)",
        },
        transition: "transform 150ms ease-in",
        transitionDelay: 1,
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 800,
        marginBottom: theme.spacing.md,
        textAlign: "center",
        fontSize: 40,

        [theme.fn.smallerThan("sm")]: {
            fontSize: 28,
            // textAlign: "left",
        },
    },
}));

export const getReview = ({ reviewBody, author = "Anonymous" }) => {
    return (
        <Paper shadow="xs" radius="lg" p="sm" withBorder>
            <Rating value={5} size={"md"} readOnly />
            <Flex>
                <Text component="p" m={0}>
                    {reviewBody}
                </Text>
            </Flex>
            <Flex>- {author}</Flex>
        </Paper>
    );
};

export function ReviewsSection() {
    const { classes, theme } = useStyles();

    const review_items = [
        { span: 4, review: reviews[1] },
        { span: 8, review: reviews[0] },
        { span: 8, review: reviews[2] },
        { span: 4, review: reviews[4] },
        { span: 3, review: reviews[5] },
        { span: 3, review: reviews[6] },
        { span: 6, review: reviews[3] },
    ].map(({ span, review }, idx) => {
        let get_duration = (span) => (span < 5 ? "4s" : "6s");
        let get_delay = (idx) => 3 * idx + "s";

        return (
            <Grid.Col xs={span} key={idx}>
                <Box
                    className={classes.review}
                    // sx={{
                    //     animation: `${scaler} ${get_duration(
                    //         span
                    //     )} ease-in-out alternate infinite`,
                    //     animationDelay: get_delay(idx),
                    // }}
                >
                    {getReview(review)}
                </Box>
            </Grid.Col>
        );
    });

    return (
        <Box py={60} className={classes.bg}>
            <Container my="md">
                <Title className={classes.title} order={3}>
                    See Why Our GATE Test Series is a Hit Amongst Aspirants
                </Title>
                <Grid>{...review_items}</Grid>
            </Container>
        </Box>
    );
}
