import { Box, Flex, Paper, Text, useMantineTheme } from "@mantine/core";
import {
    IconAnalyze,
    IconBooks,
    IconBrain,
    IconBrandGoogleAnalytics,
    IconCoinRupee,
    IconReportSearch,
} from "@tabler/icons";
import Link from "next/link";

const OurServices = () => {
    const theme = useMantineTheme();
    const service_list = [
        {
            name: "Study Materials",
            icon: <IconBooks color={theme.colors.violet[5]} size={"2rem"} />,
            link: "/materials",
        },
        {
            name: "Solve Doubts",
            icon: <IconBrain color={theme.colors.red[6]} size={"2rem"} />,
            link: "/doubts",
        },
        {
            name: "Exams",
            icon: <IconAnalyze color={theme.colors.blue[5]} size={"2rem"} />,
            link: "/dashboard",
        },
        {
            name: "Reports",
            icon: (
                <IconBrandGoogleAnalytics
                    color={theme.colors.blue[5]}
                    size={"2rem"}
                />
            ),
            link: "/testanalytics",
        },
        {
            name: "Gate Syllabus",
            icon: (
                <IconReportSearch color={theme.colors.blue[5]} size={"2rem"} />
            ),
            link: "/syllabus",
        },
        {
            name: "MTS Syllabus",
            icon: (
                <IconReportSearch color={theme.colors.green[5]} size={"2rem"} />
            ),
            link: "/syllabus/mts",
        },
        {
            name: "Refer and Earn",
            icon: <IconCoinRupee color={theme.colors.green[5]} size={"2rem"} />,
            link: "/earn",
        },
    ];
    return (
        <Paper maw={600} withBorder p={10} radius={"lg"}>
            <Box>
                <Text ta={"center"} fw={"bold"}>
                    Our services
                </Text>
            </Box>
            <Flex my={10} w={"100%"} justify={"center"} wrap={"wrap"} gap={10}>
                {service_list.map((x, i) => (
                    <Link
                        href={x.link}
                        style={{ textDecoration: "none" }}
                        key={i}
                    >
                        <Paper
                            p={10}
                            bg={
                                theme.colorScheme == "dark"
                                    ? "dark.6"
                                    : "lime.2"
                            }
                            sx={() => ({
                                cursor: "pointer",
                                "&:hover": {
                                    boxShadow: theme.shadows.md,
                                    transform: "scale(1.05)",
                                },
                                transition:
                                    "box-shadow 150ms ease, transform 200ms ease",
                            })}
                        >
                            <Flex
                                h={"100%"}
                                justify={"center"}
                                direction={"column"}
                                align={"center"}
                            >
                                <Box>{x.icon}</Box>
                                <Text ta={"center"} w={150}>
                                    {x.name}
                                </Text>
                            </Flex>
                        </Paper>
                    </Link>
                ))}
            </Flex>
        </Paper>
    );
};

export default OurServices;
