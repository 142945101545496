import Head from "next/head";
import Script from "next/script";
import { Fragment } from "react";
import { reviews } from "../../../packages/ui2/components/ReviewsSection";

export const nextYear = new Date().getFullYear() + 1;

const get_title = (title) => {
    return `${title} - MTS Mining Test Series ${nextYear}`;
};

const meta_name = {
    title: "Gate Mining Test Series - MTS (Mining Test Series)",
    description: `MTS is the India's No. 1 Mining GATE Exam Test Series provider. Thousands of GATE aspirants and Mining engineers love it. For serious GATE ${nextYear} aspirants.`,
    keywords: `gate ${nextYear},gate test series,mining test series,gate mining test series,gate exam ${nextYear},gate exam syllabus,coal mining,gold mines in india,mines and geology,ministry of mines,indian bureau of mines,kgf mines,jharkhand mines,uranium mines in india,department of mines and geology,gate exam pattern,gate exam ${nextYear},iit gate exam postponed,gate exam eligibility`,
    robots: "index, follow",
    language: "English",
    "revisit-after": "2 days",
    author: "Vivek Gupta",
};
const meta_property = {
    "og:url": "https://www.gate.mining.examtestseries.com",
    "og:title": meta_name["title"],
    "og:description": meta_name["description"],
    "og:image": "https://www.gate.mining.examtestseries.com/fullpagess.png",
};

const get_meta = (title = null) => {
    let metaname = [];
    let metaproperty = [];

    if (!!title) {
        meta_name["title"] = title;
        meta_property["og:title"] = title;
    }

    metaname = Object.keys(meta_name).map((key, idx) => (
        <meta key={"name_" + idx} name={key} content={meta_name[key]} />
    ));
    metaproperty = Object.keys(meta_property).map((key, idx) => (
        <meta key={"prop_" + idx} name={key} content={meta_property[key]} />
    ));
    return [...metaname, ...metaproperty];
};

const reviews_microdata = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Product",
    aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        reviewCount: "3",
        bestRating: "5",
        worstRating: "1",
    },
    description:
        "Gate Mining Test Series - ExamTestSeries offers Online Test Series for GATE Exam. Recommended for serious GATE aspirants. All testseries are designed by experienced members.",
    name: "Gate Mining Test Series - ExamTestSeries",
    image: "https://www.gate.mining.examtestseries.com/mdark.jpg",
    review: [...reviews],
});

export const seo_schema = {
    index: {
        title: get_title("Gate Mining Test Series"),
        links: [
            <link
                key={"apple-touch-icon.png"}
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />,
            <link
                key={"favicon-32x32.png"}
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />,
            <link
                key={"favicon-16x16.png"}
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />,
        ],
        meta: get_meta(),
    },
    dashboard: {
        title: get_title("Dashboard - MTS (Mining Test Series)"),
        links: [
            <link
                key={"apple-touch-icon.png"}
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />,
            <link
                key={"favicon-32x32.png"}
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />,
            <link
                key={"favicon-16x16.png"}
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />,
        ],
        meta: get_meta("Dashboard - MTS (Mining Test Series)"),
    },
    syllabus: {
        title: get_title("Syllabus - MTS (Mining Test Series)"),
        links: [
            <link
                key={"apple-touch-icon.png"}
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />,
            <link
                key={"favicon-32x32.png"}
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />,
            <link
                key={"favicon-16x16.png"}
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />,
        ],
        meta: get_meta("Syllabus - MTS (Mining Test Series)"),
    },

    testid: {
        title: get_title("Test Active - MTS (Mining Test Series)"),
        meta: get_meta("Test paper - MTS (Mining Test Series)"),
        links: [
            <link
                key={"apple-touch-icon.png"}
                rel="apple-touch-icon"
                sizes="180x180"
                href="/green-icon/apple-touch-icon.png"
            />,
            <link
                key={"favicon-32x32.png"}
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/green-icon/favicon-32x32.png"
            />,
            <link
                key={"favicon-16x16.png"}
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/green-icon/favicon-16x16.png"
            />,
        ],
    },
    performance_report: {
        title: get_title("Performance - MTS (Mining Test Series)"),
        links: [
            <link
                key={"apple-touch-icon.png"}
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />,
            <link
                key={"favicon-32x32.png"}
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />,
            <link
                key={"favicon-16x16.png"}
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />,
        ],
        meta: get_meta("Performance - MTS (Mining Test Series)"),
    },
    reviews_microdata,
};

export const get_seo = (name) => {
    const [seo, microdatahomepage] = [
        seo_schema[name],
        seo_schema["reviews_microdata"],
    ];

    return (
        <Fragment>
            <Head>
                <title>{seo.title}</title>
                {seo.links}
                {...seo.meta}
            </Head>
            {name == "index" && (
                <Script
                    id="microdata-homepage"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: microdatahomepage,
                    }}
                />
            )}
        </Fragment>
    );
};
